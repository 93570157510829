
import Table, { RowData } from "components/Table";
import { useMemo } from "react";
import { Attempts } from "types/assessments";

interface IAttemptTableProps {
  attempts: Attempts[];
}

const AttemptsTable = ({ attempts }: IAttemptTableProps) => {
  const data = useMemo(() => {
    return attempts.map((attempt, pos) => {
      return { ...attempt, index: pos + 1 };
    });
  }, [attempts]);

  return (
    <Table
      title={{
        label: "Attempts",
      }}
      columns={[
        {
          id: "index",
          label: "S/N",
        },
        {
          id: "admission_no",
          label: "ADMISSION NUMBER",
          renderer:(item:RowData)=>{
            return item?.student?.admission_no || "-";
          }
        },
        {
          id: "student_name",
          label: "STUDENT NAME",
          renderer:(item:RowData)=>{
            return item?.student?.account?.name || "-";
          }
        },

        {
          id: "student_email",
          label: "STUDENT EMAIL",
          renderer:(item:RowData)=>{
            return item?.student?.account?.email || "-";
          }
        },
        {
          id: "score",
          label: "SCORE",
        },

        {
          id: "submittedAt",
          label: "SUBMITTED AT",
        },
      ]}
      rows={data}
    />
  );
};

export default AttemptsTable;
