import React, { useMemo } from "react";
import TextField from "components/Form/TextField";
import { Box, MenuItem } from "@mui/material";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useStudentGroups } from "hooks/students";
import PageLayout from "components/PageLayout";
import Button from "components/button/Button";
import { IAccountRole } from "types/auth";
import { IUsers } from "types/users";

interface IAddOrEditUserFormProps {
  initialUser?: Partial<IUsers>;
  inEditMode?: boolean;
  onSubmit: (users: IUsers) => Promise<void>;
}

const AddOrEditUserForm = ({
  initialUser,
  onSubmit,
  inEditMode,
}: IAddOrEditUserFormProps) => {
  const validator = useMemo(() => {
    const fieldValidators: any = {
      name: Yup.string()
        .required("Enter user's name")
        .min(3, "Name must be more than two characters"),
      email: Yup.string()
        .required("Enter a valid email")
        .email("Enter a valid email"),
      role: Yup.string().required("Select role"),
    };

    if (!inEditMode) {
      fieldValidators.password = Yup.string()
        .required("Enter a password for user account")
        .min(4, "Enter more than three characters");
    }
    return Yup.object().shape(fieldValidators);
  }, [inEditMode]);

  const {
    isLoading,
    isFetching,
    error,
    data: groups,
    refetch,
  } = useStudentGroups();

  return (
    <PageLayout
      loading={isLoading || isFetching}
      error={error}
      onRetry={() => refetch()}
    >
      <Formik
        initialValues={{
          name: initialUser?.name,
          email: initialUser?.email,
          role: initialUser?.role,
          password: "",
        }}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);
          await onSubmit({
            email: values.email!,
            name: values.name!,
            role: values.role!,
            password: values.password,
          });
          setSubmitting(false);
        }}
        validationSchema={validator}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          isSubmitting,
        }) => (
          <Form
            style={{ textAlign: "center", marginTop: 12, marginBottom: 24 }}
          >
            <TextField
              type="text"
              name="name"
              error={!!(errors.name && touched.name)}
              helperText={errors.name}
              onChange={handleChange}
              onBlur={handleBlur}
              required
              value={values.name || ""}
              label="User's Name"
              variant="outlined"
              fullWidth
              style={{ marginBottom: 24 }}
            />

            {!inEditMode && (
              <TextField
                type="email"
                name="email"
                error={!!(errors.email && touched.email)}
                helperText={errors.email}
                onChange={handleChange}
                onBlur={handleBlur}
                required
                value={values.email || ""}
                label="User's Email Address"
                variant="outlined"
                fullWidth
                style={{ marginBottom: 24 }}
              />
            )}

            <TextField
              name="role"
              error={!!(errors.role && touched.role)}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={errors.role}
              label="User's Role"
              style={{ marginBottom: 24, textAlign: "left" }}
              select
              value={values?.role}
              fullWidth
            >
              <MenuItem key={IAccountRole.ADMIN} value={IAccountRole.ADMIN}>
                {IAccountRole.ADMIN}
              </MenuItem>
              <MenuItem key={IAccountRole.TUTOR} value={IAccountRole.TUTOR}>
                {IAccountRole.TUTOR}
              </MenuItem>
            </TextField>

            {!inEditMode && (
              <TextField
                type="password"
                name="password"
                error={!!(errors.password && touched.password)}
                helperText={errors.password}
                onChange={handleChange}
                onBlur={handleBlur}
                required
                value={values.password || ""}
                label="Student's Password"
                variant="outlined"
                fullWidth
              />
            )}
            <Box mt={4} display="flex" justifyContent="center">
              <Button type="submit" variant="contained" loading={isSubmitting}>
                SUBMIT
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </PageLayout>
  );
};

export default AddOrEditUserForm;
