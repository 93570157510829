import { Stack } from "@mui/material";
import { TableHeaderNode } from "components/Common";
import Table, { RowData } from "components/Table";
import { Category } from "types/questions";
import { NavLink, useHistory } from "react-router-dom";
import { addQuestionsUrl, viewCategoryDetailsUrl } from "config/urls";
import Dropdown from "components/Dropdown";
import { Delete, Edit } from "@mui/icons-material";
import { useMemo } from "react";
import Button from "components/button/Button";
import { isAdminUser } from "api/cookie";

interface ICategoriesTableProps {
  categories: Category[];
  groupId?: number;
  showViewCategory?: boolean;
  showActionButtons?: boolean;
  onDeleteCategoryClicked?: (category: Category) => Promise<void> | any;
  onEditCategoryClicked?: (category: Category) => Promise<void> | any;
  onAddCategoryClicked?: Function;
}

const CategoriesTable = ({
  categories,
  onDeleteCategoryClicked,
  onEditCategoryClicked,
  onAddCategoryClicked,
  showViewCategory,
  showActionButtons,
  groupId,
}: ICategoriesTableProps) => {
  const data = useMemo(() => {
    return categories.map((value, pos) => ({
      ...value,
      index: pos + 1,
    }));
  }, [categories]);

  const history = useHistory();
  const isAdmin = isAdminUser();
  return (
    <Table
      title={{
        label: "Subject",
      }}
      ctaLabel="Add Subject"
      onCTAClicked={isAdmin ? onAddCategoryClicked : undefined}
      preHeaderNode={
        <TableHeaderNode style={{ marginTop: 16 }} title="Subject" />
      }
      rows={data}
      columns={[
        {
          id: "index",
          label: "S/N",
        },
        {
          id: "name",
          label: "NAME",
          renderer: (item: RowData) => {
            const rowItem = item as Category;

            return showViewCategory ? (
              <NavLink
                style={{
                  color: "#07978c",
                  cursor: "pointer",
                  textDecoration: "none",
                }}
                to={viewCategoryDetailsUrl(rowItem.id, groupId!)}
              >
                {rowItem.name}
              </NavLink>
            ) : (
              rowItem.name
            );
          },
        },
        {
          id: "level",
          label: "Class Level",
          renderer: (item: RowData) => item.level?.level_name,
        },
        {
          id: "",
          label: "ACTION",
          disabled: !showViewCategory,
          renderer: (item: RowData) => {
            return (
              <Stack direction="row" justifyContent="center" gap="16px">
                <Button
                  variant="contained"
                  onClick={() => {
                    history.push(viewCategoryDetailsUrl(item.id, groupId!));
                  }}
                >
                  VIEW SUBJECT
                </Button>

                <Button
                  variant="contained"
                  color="info"
                  onClick={() => {
                    history.push(addQuestionsUrl(groupId!, item.id));
                  }}
                >
                  ADD QUESTION
                </Button>
              </Stack>
            );
          },
        },
        {
          id: "",
          label: "ACTIONS",
          disabled: !showActionButtons || !isAdmin,
          renderer: (item: RowData) => {
            const rowItem = item as Category;
            return (
              <Stack direction="row" justifyContent="center">
                <Dropdown
                  containerStyle={{ marginLeft: 16, display: "inline" }}
                  title="Actions"
                  buttonProps={{
                    sx: {
                      borderRadius: 25,
                      background: "none !important",
                      border: "1px solid #CCC",
                      color: "black",
                    },
                  }}
                  items={[
                    {
                      label: "UPDATE",
                      icon: <Edit />,
                      onClick: () =>
                        onEditCategoryClicked && onEditCategoryClicked(rowItem),
                    },
                    {
                      label: "DELETE",
                      icon: <Delete />,
                      onClick: () =>
                        onDeleteCategoryClicked &&
                        onDeleteCategoryClicked(rowItem),
                    },
                  ]}
                />
              </Stack>
            );
          },
        },
      ]}
    />
  );
};

export default CategoriesTable;
