import { useState, useEffect } from "react";
import DashboardLayout from "components/Dashboard/DashboardLayout";
import { useAuthProfile } from "hooks/auth";
import { RouteComponentProps } from "react-router";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import DashboardHome from "views/dashboard/home";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import NotFound from "components/NotFound";
import CategoryView from "views/categories";
import AddQuestions from "views/categories/AddQuestions";
import EditQuestion from "views/categories/EditQuestion";
import PageLayout from "components/PageLayout";
import StudentsView from "views/students";
import StudentGroupView from "views/students/group";
import AssessmentsView from "views/assessments";
import AddAssessment from "views/assessments/add";
import EditAssessment from "views/assessments/edit";
import AssessmentDetailsView from "views/assessments/info";
import { IAccountRole } from "types/auth";
import StudentAssessmentsView from "views/students/assessments";
import AttemptAssessmentView from "views/students/attempt";
import GroupInfo from "views/students/groupInfo";
import CategoryInfo from "views/categories/info";
import UsersView from "views/users";
import AddInstitution from "views/institution";

/** Renders the dashboard routes */
const DashboardRoute = (props: RouteComponentProps) => {
  const {
    data: profile,
    isLoading,
    isFetching,
    error,
    refetch,
  } = useAuthProfile();

  const history = useHistory();

  useEffect(() => {
    const unRegister = history.listen(() => {
      window.scrollTo({
        left: 0,
        top: 0,
        behavior: "smooth",
      });
    });
    return unRegister;
  }, [history]);

  return (
    <PageLayout
      loading={isFetching || isLoading}
      error={error}
      onRetry={() => refetch()}
    >
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DashboardLayout account={profile!}>
          <Switch>
            <Route
              path="/dashboard/"
              exact
              render={() => <Redirect to="/dashboard/home" />}
            />
            <Route
              exact
              path="/dashboard/home"
              component={
                profile?.role === IAccountRole.STUDENT
                  ? StudentAssessmentsView
                  : DashboardHome
              }
            />

            <Route
              exact
              path="/dashboard/attempt/:id"
              component={AttemptAssessmentView}
            />

            <Route
              exact
              path="/dashboard/assessments"
              component={AssessmentsView}
            />

            <Route
              exact
              path="/dashboard/assessments/add"
              component={AddAssessment}
            />

            <Route
              exact
              path="/dashboard/assessments/:id/edit"
              component={EditAssessment}
            />

            <Route
              exact
              path="/dashboard/assessments/:id/details"
              component={AssessmentDetailsView}
            />

            <Route
              exact
              path="/dashboard/groups"
              component={StudentGroupView}
            />

            <Route exact path="/dashboard/students" component={StudentsView} />

            <Route exact path="/dashboard/users" component={UsersView} />

            <Route
              exact
              path="/dashboard/categories"
              component={CategoryView}
            />
            <Route
              exact
              path="/dashboard/groups/:id"
              component={GroupInfo}
            />
               <Route
              exact
              path="/dashboard/groups/:groupId/categories/:categoryId"
              component={CategoryInfo}
            />
            <Route
              exact
              path="/dashboard/groups/:groupId/categories/:categoryId/questions"
              component={AddQuestions}
            />
            <Route
              exact
              path="/dashboard/groups/categories/questions/:id/edit"
              component={EditQuestion}
            />

            {/* Place holder until cloud feature for this is done */}
            <Route
              exact
              path="/dashboard/institution"
              component={AddInstitution}
            />

            <Route path="*" render={() => <NotFound homeUrl="/dashboard" />} />
          </Switch>
        </DashboardLayout>
      </LocalizationProvider>
    </PageLayout>
  );
};

export default DashboardRoute;
