import React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Sidebar from "./Sidebar";
import { appName } from "config/copy";
import { IAccount } from "types/auth";
import { Link } from "react-router-dom";
import { primary } from "config/colors";
import { Stack } from "@mui/material";

export type IDashboardLayoutProps = {
  children: React.ReactNode;
  drawerWidth?: number;
  account: IAccount;
};

const defaultDrawerWidth = 260;

const DashboardLayout = ({
  children,
  drawerWidth,
  account,
}: IDashboardLayoutProps) => {
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setIsDrawerOpen((isOpen) => !isOpen);
  };

  drawerWidth = drawerWidth || defaultDrawerWidth;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        elevation={0}
        sx={{
          width: { md: `calc(100% - ${drawerWidth}px)` },
          ml: { md: `${drawerWidth}px` },
          boxShadow: "0 3px 6px rgb(0 0 0 / 12%)",
        }}
      >
        <Toolbar
          sx={{
            background: "#fff",
            color: "#000",
            elevation: 0,
            minHeight: "64px !important",
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
          >
            <Stack direction="row" gap="8px" width="fit-content" margin="0px">
              <img height={28} src="/logo192.png" alt="logo" />
              <Typography color="primary" variant="h6" noWrap component="div">
                Clique
              </Typography>
            </Stack>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{
                display: { xs: "flex", md: "none" },
                alignItems: "center",
                borderRadius: "100vh",
              }}
            >
              <MenuIcon />
            </IconButton>
          </Stack>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          variant="temporary"
          open={isDrawerOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              background: "#1d2b36",
              color: "#fff",
            },
          }}
        >
          <Sidebar
            account={account}
            onItemClicked={() => setIsDrawerOpen(false)}
          />
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", md: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              background: "#1d2b36",
              color: "#fff",
            },
          }}
          open
        >
          <Sidebar account={account} />
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          mt: 6,
          background: "#f5f5ff",
          minHeight: `calc(100vh - 50px)`,
          width: { md: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default DashboardLayout;
