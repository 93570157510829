import PageLayout from "components/PageLayout";
import { useCategories } from "hooks/categories";
import { useStudentGroups } from "hooks/students";
import { Assessment } from "types/assessments";

import TextField from "components/Form/TextField";
import { Box, MenuItem, Select } from "@mui/material";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import Button from "components/button/Button";
import { Institution } from "types/users";

interface IInstitutionFormProps {
  onSubmit: (institution: Institution) => Promise<void | any>;
}

const validator = Yup.object().shape({
  name: Yup.string()
    .required("Account Name")
    .min(3, "Three ore more characters allowed"),
  institution_name: Yup.string().required("Enter Institution name"),
  location: Yup.string().required("Enter location"),
  email: Yup.string()
    .email("Please enter a valid email")
    .required("Please enter a valid email"),
  phone: Yup.string().required("Please phone number"),
  password: Yup.string()
    .required("Enter a password for user account")
    .min(4, "Enter more than three characters"),
});

const InstitutionForm = ({ onSubmit }: IInstitutionFormProps) => {
  useCategories();

  return (
    <PageLayout loading={false} error={false}>
      <Formik
        initialValues={{
          name: "",
          institution_name: "",
          email: "",
          phone: "",
          password: "",
          location: "",
        }}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);
          await onSubmit(values);
          setSubmitting(false);
        }}
        validationSchema={validator}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          isSubmitting,
        }) => (
          <Form
            style={{ textAlign: "center", marginTop: 12, marginBottom: 24 }}
          >
            <TextField
              type="text"
              name="institution_name"
              error={!!(errors.institution_name && touched.institution_name)}
              helperText={errors.institution_name}
              onChange={handleChange}
              onBlur={handleBlur}
              required
              value={values.institution_name || ""}
              label="Institution Name"
              variant="outlined"
              fullWidth
              style={{ marginBottom: 24 }}
            />

            <TextField
              type="text"
              name="phone"
              error={!!(errors.phone && touched.phone)}
              helperText={errors.phone}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.phone || ""}
              label="Institution Phone Number"
              variant="outlined"
              fullWidth
              style={{ marginBottom: 24 }}
            />

            <TextField
              type="text"
              name="location"
              error={!!(errors.location && touched.location)}
              helperText={errors.location}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.location || ""}
              label="Institution Address"
              variant="outlined"
              fullWidth
              style={{ marginBottom: 24 }}
            />

            <TextField
              type="text"
              name="name"
              error={!!(errors.name && touched.name)}
              helperText={errors.name}
              onChange={handleChange}
              onBlur={handleBlur}
              required
              value={values.name || ""}
              label="User Account Name"
              variant="outlined"
              fullWidth
              style={{ marginBottom: 24 }}
            />

            <TextField
              type="email"
              name="email"
              error={!!(errors.email && touched.email)}
              helperText={errors.email}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email || ""}
              label="User Account Email"
              variant="outlined"
              fullWidth
              style={{ marginBottom: 24 }}
            />
               <TextField
                type="password"
                name="password"
                error={!!(errors.password && touched.password)}
                helperText={errors.password}
                onChange={handleChange}
                onBlur={handleBlur}
                required
                value={values.password || ""}
                label="User Account Password"
                variant="outlined"
                fullWidth
              />

            <Box display="flex" justifyContent="flex-end">
              <Button
                type="submit"
                variant="contained"
                style={{ marginTop: 32, minWidth: 180 }}
                loading={isSubmitting}
              >
                SUBMIT
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </PageLayout>
  );
};

export default InstitutionForm;
