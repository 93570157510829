import { Grid, Pagination, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import ConfirmationModal from "components/ConfirmationModal";
import Countdown from "components/Countdown";
import PageLayout from "components/PageLayout";
import Button from "components/button/Button";
import { white } from "config/colors";
import { dashboardHomeUrl } from "config/urls";
import { useAddAttempt, useSubmitAttempt } from "hooks/assessments";
import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import { Prompt, useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";

export type SubmissionItem = {
  question_id: number;
  selected_option_id: number;
};

const AttemptAssessmentView = () => {
  const assessmentId = useParams<{ id: string }>().id;
  const { isLoading, mutate, data: attemptDetails, error } = useAddAttempt();
  const { mutateAsync } = useSubmitAttempt();
  const [selectedAnswers, setSelectedAnswers] = useState<SubmissionItem[]>([]);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState<boolean>(false);

  const history = useHistory();
  const [page, setPage] = React.useState(1);

  const scrollToTop = () => {
    window.scrollTo({
      left: 0,
      top: 0,
      behavior: "smooth",
    });
  }
  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
    scrollToTop()
  };

  useEffect(() => {
    mutate(Number(assessmentId));
  }, [assessmentId]);

  const retry = () => mutate(Number(assessmentId));

  const updateSelectedAnswer = (
    questionId: number,
    selectedOptionId: number
  ) => {
    const previous = [...selectedAnswers];

    const index = previous.findIndex(
      (value) => value.question_id === questionId
    );
    if (index > -1) {
      previous[index].selected_option_id = selectedOptionId;
    } else {
      previous.push({
        question_id: questionId,
        selected_option_id: selectedOptionId,
      });
    }

    setSelectedAnswers(previous);
  };

  const submitAnswers = async () => {
    try {
      await mutateAsync({ assessmentId, answers: selectedAnswers });
      toast("Assessment submitted successfully!", { type: "success" });
      setHasSubmitted(true);
      setTimeout(() => {
        history.replace(dashboardHomeUrl());
      }, 1500);
    } catch (error) {
      // toast an error.
      toast(
        "Error while submitting assessment. Please contact admin for help resetting your timer",
        { type: "error", autoClose: 60 * 1000 }
      );
      history.replace(dashboardHomeUrl());
    } finally {
      setShowConfirmationModal(false);
    }
  };

  const getOptionsAlhpaLabel = (index: number) => {
    const label = ["A", "B", "C", "D", "E", "F", "G", "H"];
    return label[index];
  };

  const getNavigationPage = (method: "next" | "prev") => {
    if(attemptDetails?.data?.questions) {
      if (method === "next") {
        (page + 1) > attemptDetails?.data?.questions?.length
          ? setPage(attemptDetails?.data?.questions?.length) 
          : setPage(page + 1);
      }
      if(method === "prev") {
        page - 1 < 1 ? setPage(1) : setPage(page - 1);
      }
      scrollToTop()
    }
  };

  return (
    <PageLayout loading={isLoading} error={error} onRetry={() => retry()}>
      <Prompt
        when={!hasSubmitted}
        message="You have un-saved changes. Are you sure you want to leave?"
      />
      <Box
        display="flex"
        padding="12px"
        justifyContent="space-between"
        position="sticky"
        top={60}
        zIndex={1}
        borderBottom="solid 2px#f5f5ff"
        gap="12px"
        sx={{
          backgroundColor: white,
          flexDirection: { xs: "column", md: "row" },
          alignItems: { xs: "start", md: "center" },
        }}
      >
        <Typography variant="h6">
          {attemptDetails?.data?.category?.name} -{" "}
          {attemptDetails?.data?.group?.name}
        </Typography>
        <Box display="flex" gap="12px">
          <Countdown
            onEllapsed={() => submitAnswers()}
            durationInSec={(attemptDetails?.data?.duration || 1) * 60}
          />
          <Button
            variant="contained"
            loading={hasSubmitted}
            style={{ minWidth: 150 }}
            onClick={() => setShowConfirmationModal(true)}
          >
            SUBMIT
          </Button>
        </Box>
      </Box>
      <Box sx={{ backgroundColor: white, padding: { xs: "12px", md: "16px" } }}>
        <Stack spacing={2}>
          <Typography fontWeight={700}>Question: {page}</Typography>
          <Box sx={{minHeight: {xs: "50vh", md: "60vh"}}}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <ReactQuill
                  value={
                    attemptDetails?.data?.questions?.[page - 1].description
                  }
                  readOnly
                  className="quiz"
                  style={{ marginBottom: 8 }}
                />
              </Grid>
              {attemptDetails?.data?.questions?.[page - 1].options.map(
                (option, index) => (
                  <Grid item xs={12} md={6}>
                    <Box
                      display="flex"
                      key={option.id}
                      flexDirection="row"
                      sx={{ marginBottom: 2 }}
                    >
                      <input
                        className="asButton"
                        type="radio"
                        name={`question-${
                          attemptDetails?.data?.questions?.[page - 1].id
                        }`}
                        id={`question-${
                          attemptDetails?.data?.questions?.[page - 1].id
                        }-${index}`}
                        value={option.id}
                        onChange={() => {
                          updateSelectedAnswer(
                            attemptDetails?.data?.questions?.[page - 1].id,
                            option.id
                          );
                        }}
                      />
                      <label
                        htmlFor={`question-${
                          attemptDetails?.data?.questions?.[page - 1].id
                        }-${index}`}
                        style={{ display: "flex", gap: "5px" }}
                      >
                        <Typography>{getOptionsAlhpaLabel(index)}.</Typography>{" "}
                        <ReactQuill
                          value={option.description}
                          readOnly
                          className="quiz"
                        />
                      </label>
                    </Box>
                  </Grid>
                )
              )}
            </Grid>
          </Box>
          <Box
            display="flex"
            gap="12px"
            sx={{
              justifyContent: { xs: "flex-start", md: "space-between" },
              flexDirection: { xs: "column", md: "row" },
            }}
          >
            <Pagination
              count={attemptDetails?.data?.questions.length}
              page={page}
              onChange={handleChangePage}
            />
            <Box display="flex" gap="12px">
              <Button
                onClick={() => getNavigationPage("prev")}
                color="secondary"
                disabled={page === 1}
              >
                Prev
              </Button>
              <Button
                onClick={() => getNavigationPage("next")}
                color="secondary"
                disabled={page === attemptDetails?.data.questions.length}
              >
                Next
              </Button>
            </Box>
          </Box>
        </Stack>
      </Box>

      <ConfirmationModal
        open={showConfirmationModal}
        title="Are you sure"
        prompt="You want to submit this assessment?"
        onCancel={() => setShowConfirmationModal(false)}
        onConfirm={() => submitAnswers()}
      />
    </PageLayout>
  );
};

const QuestionContainer = styled.div`
  border-bottom: 2px dashed #ccc;
  padding: 16px;
`;

export default AttemptAssessmentView;
