import { get, post, put, deleteRequest } from "api";
import { Question as FormQuestion } from "components/Questions/AddQuestionForm";
import { MutationOptions } from "hooks/types";
import { QueryOptions, useMutation, useQuery } from "react-query";
import { GenericAPIResponse } from "types/generic";
import { Question } from "types/questions";

export const useQuestionInfo = (
  questionId: string,
  options?: QueryOptions<GenericAPIResponse<Question>>
) => {
  return useQuery<GenericAPIResponse<Question>, any>(
    ["questions/", questionId],
    () => get(`admin/questions/${questionId}`),
    { ...(options || {}) }
  );
};

export const useAddQuestion = (
  options?: MutationOptions<GenericAPIResponse<Question[]>>
) => {
  return useMutation(
    (value: { questions: FormQuestion[]; group_id: number; category_id: number; }) =>
      post("admin/questions/", value),
    {
      ...(options || {}),
    }
  );
};


export const useUpdateQuestion = (
  options?: MutationOptions<GenericAPIResponse<Question>>
) => {
  return useMutation(
    (value: {question: FormQuestion, id: string}) =>
      put(`admin/questions/${value.id}`, value.question),
    {
      ...(options || {}),
    }
  );
};
