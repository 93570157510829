import { Grid, Typography } from "@mui/material";
import DeleteModal from "components/DeleteModal";
import PageLayout from "components/PageLayout";
import CategoriesTable from "components/Questions/CategoryListTable";
import CategoryQuestionsTable from "components/Questions/CategoryQuestionsTable";
import StatsCard from "components/StatsCard";
import { numberWithCommas } from "config/number";
import { useDeleteQuestion } from "hooks/categories";
import { useGroupDetails } from "hooks/students";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Question } from "types/questions";

const GroupInfo = () => {
  const id = useParams<{ id: string }>().id;
  const { data, refetch, isLoading, isFetching, error } = useGroupDetails(id);
  const { mutateAsync: deleteQuestionFromServer } = useDeleteQuestion();

  const [selectedQuestionToDelete, setSelectedQuestionToDelete] =
    useState<Question>();
  const categoryInfo = data?.data;

  const deleteSelectedQuestion = async () => {
    if (selectedQuestionToDelete) {
      try {
        await deleteQuestionFromServer(selectedQuestionToDelete.id);
        toast("Question deleted successfully!", { type: "success" });
      } catch (error) {
        toast("Couldn't delete question", { type: "error" });
      } finally {
        setSelectedQuestionToDelete(undefined);
        refetch();
      }
    }
  };

  return (
    <PageLayout
      error={error}
      loading={isLoading || isFetching}
      onRetry={() => refetch()}
    >
      <Typography variant="h6" fontWeight="bold" style={{ marginTop: 16 }}>
        CLASS DETAILS
      </Typography>

      <Grid container spacing={2} sx={{ marginTop: 2 }}>
        <Grid item xs={6} sm={4} md={4}>
          <StatsCard
            bgColor="#18b5ca"
            hoverBg="#33a1b0"
            body={categoryInfo?.name || "-"}
            footer="CLASS NAME"
          />
        </Grid>

        <Grid item xs={6} sm={4} md={4}>
          <StatsCard
            hoverBg="#283db5"
            body={numberWithCommas(categoryInfo?.subjects?.length || 0)}
            footer="ADDED SUBJECTS"
          />
        </Grid>
      </Grid>

      <div style={{ marginTop: 32 }}>

        <CategoriesTable
          categories={categoryInfo?.subjects!}
          showViewCategory={true}
          groupId={categoryInfo?.id}
        />
      </div>

      <DeleteModal
        open={Boolean(selectedQuestionToDelete)}
        title="Are you sure?"
        prompt="You want to delete this question from this subject?"
        onCancel={() => setSelectedQuestionToDelete(undefined)}
        onConfirm={deleteSelectedQuestion}
      />
    </PageLayout>
  );
};

export default GroupInfo;
