export const viewCategoriesUrl = () => "/dashboard/categories";
export const viewCategoryDetailsUrl = (categoryId: number | string, groupId: number | string) => `/dashboard/groups/${groupId}/categories/${categoryId}`;
export const viewClassDetailsUrl = (groupId: number | string) => `/dashboard/groups/${groupId}`;
export const viewQuestionsUrl = (categoryId: number | string) => `/dashboard/categories/${categoryId}`;
export const addQuestionsUrl = (groupId: number | string, categoryId: number | string)=> `/dashboard/groups/${groupId}/categories/${categoryId}/questions`
export const editQuestionUrl = (questionId: number | string)=> `/dashboard/groups/categories/questions/${questionId}/edit`
export const viewStudentsUrl = () => "/dashboard/students";
export const viewStudentGroupsUrl = () => "/dashboard/groups";
export const addAssessmentsUrl = () => "/dashboard/assessments/add";
export const viewAssessmentsUrl = () => "/dashboard/assessments";
export const viewAssessmentDetailsUrl = (id: string | number) => `/dashboard/assessments/${id}/details`;
export const viewEditAssessmentUrl = (id: string | number) => `/dashboard/assessments/${id}/edit`;
export const attemptAssessmentUrl = (assessmentId: string | number) => `/dashboard/attempt/${assessmentId}`;
export const dashboardHomeUrl = () => "/dashboard/home";
export const viewUsersUrl = () => "/dashboard/users";
export const addInstitutionUrl = () => "/dashboard/institution"; //Place holder to be replace by clique cloud feature