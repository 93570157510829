import { Card, Grid, Typography } from "@mui/material";
import AddQuestionForm, {
  Question as FormQuestion,
} from "components/Questions/AddQuestionForm";
import { useQuestionInfo, useUpdateQuestion } from "hooks/questions";
import { useMemo, useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import { Question } from "types/questions";
import { useHistory, useParams } from "react-router-dom";
import PageLayout from "components/PageLayout";
import { viewCategoryDetailsUrl, viewQuestionsUrl } from "config/urls";

/** Renders the edit question */
const EditQuestion = () => {
  const history = useHistory();
  const questionId = useParams<{ id: string }>().id;
  const {
    data: questionInfo,
    isLoading,
    error,
    refetch,
  } = useQuestionInfo(questionId);
  const question: Question | undefined = questionInfo?.data;
  const { mutateAsync: updateQuestion } = useUpdateQuestion();

  const submitQuestionsToServer = async (formQuestion: FormQuestion) => {
    try {
      await updateQuestion({
        question: formQuestion,
        id: question!.id.toString(),
      });
      toast("Question updated successfully", { type: "success" });
      history.replace(viewCategoryDetailsUrl(question!.category_id, question!.group_id));
    } catch (error) {
      toast("Couldn't update question", { type: "error" });
    }
  };

  const initialQuestionForForm = useMemo(() => {
    return question
      ? {
          ...question,
          options: question.options.map((option) => ({
            ...option,
            uid: option.id.toString(),
            is_answer: option.id === question.answer_option_id,
          })),
        }
      : undefined;
  }, [question]);

  return (
    <PageLayout error={error} loading={isLoading} onRetry={() => refetch()}>
      <div>
        <Grid container spacing={4}>
          <Grid item xs={12} md={12} lg={7}>
            <Card sx={{ borderRadius: 3 }}>
              <Header>
                <Typography variant="subtitle1" fontWeight="bold">
                  EDIT QUESTION
                </Typography>
              </Header>

              <AddQuestionForm
                initialQuestion={initialQuestionForForm}
                onSubmit={submitQuestionsToServer}
                inEditMode
              />
            </Card>
          </Grid>
        </Grid>
      </div>
    </PageLayout>
  );
};

/**
 * -------------------
 * STYLED COMPONENTS
 * -------------------
 */

const Header = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 2px dashed #efefee;
`;

export default EditQuestion;
