import { Stack } from "@mui/material";
import Table, { RowData } from "components/Table";
import Button from "components/button/Button";
import { useMemo } from "react";
import { IUsers } from "types/users";

interface IUserTableProps {
  users: IUsers[];
  onAddUserClicked?: Function;
  onDeleteUser?: (user: IUsers) => void;
  onEditUser?: (user: IUsers) => void;
  title?: string;
}

const UsersListTable = ({
  users,
  onAddUserClicked,
  onDeleteUser,
  onEditUser,
  title,
}: IUserTableProps) => {
  const data = useMemo(() => {
    return users.map((user, pos) => {
      return { ...user, index: pos + 1 };
    });
  }, [users]);

  return (
    <Table
      title={{
        label: title || "Users",
      }}
      ctaLabel="Add User"
      onCTAClicked={onAddUserClicked}
      columns={[
        {
          id: "index",
          label: "S/N",
        },
        
        {
          id: "name",
          label: "Name",
        },
        {
          id: "email",
          label: "Email",
        },
 
        {
          id: "role",
          label: "Role",
        },
        {
          id: "actions",
          label: "Actions",
          renderer: (item: RowData) => {
            return (
              <Stack direction="row" gap="12px" justifyContent="center">
                {onEditUser && (
                  <Button
                    onClick={() => {
                      onEditUser(item as IUsers);
                    }}
                    sx={{
                      minWidth: "max-content !important",
                      padding: "8px 28px !important",
                    }}
                    variant="contained"
                  >
                    EDIT
                  </Button>
                )}

                {onDeleteUser && (
                  <Button
                    onClick={() => onDeleteUser(item as IUsers)}
                    variant="contained"
                    sx={{ minWidth: "max-content !important" }}
                    color="error"
                  >
                    DELETE
                  </Button>
                )}
              </Stack>
            );
          },
        },
      ]}
      rows={data}
    />
  );
};

export default UsersListTable;
