import { Question } from "types/questions";
import { TableHeaderNode } from "components/Common";
import Table, { RowData } from "components/Table";
import ReactQuill from "react-quill";
import { Box, Stack } from "@mui/material";
import { useHistory } from "react-router-dom";
import { addQuestionsUrl, editQuestionUrl } from "config/urls";
import { useMemo } from "react";
import Button from "components/button/Button";

interface ICategoryQuestionsTableProps {
  questions: Question[];
  categoryId: number;
  groupId: number;
  onDeleteQuestion?: (question: Question) => void;
}

/* Renders questions*/
const CategoryQuestionsTable = ({
  questions,
  onDeleteQuestion,
  categoryId,
  groupId
}: ICategoryQuestionsTableProps) => {
  const history = useHistory();

  const data = useMemo(() => {
    return questions.map((value, pos) => ({
      ...value,
      index: pos + 1,
    }));
  }, [questions]);

  return (
    <Table
      title={{
        label: "Questions",
      }}
      ctaLabel="Add Question"
      onCTAClicked={() => history.push(addQuestionsUrl(groupId, categoryId))}
      preHeaderNode={
        <TableHeaderNode style={{ marginTop: 16 }} title="QUESTIONS" />
      }
      rows={data}
      columns={[
        {
          id: "index",
          label: "S/N",
        },
        {
          id: "description",
          label: "DESCRIPTION",
          renderer: (item: RowData) => {
            const rowItem = item as Question;
            return (
              <ReactQuill
                readOnly
                value={rowItem.description}
                className="readonly-quill"
              />
            );
          },
        },
        {
          id: "score",
          label: "ATTAINABLE SCORE",
        },
        {
          id: "options",
          label: "NUMBER OF OPTIONS",
          renderer: (item: RowData) => {
            const rowItem = item as Question;
            return <b>{rowItem.options?.length || "-"}</b>;
          },
        },
        {
          id: "actions",
          label: "ACTIONS",
          minWidth: 300,
          renderer: (item: RowData) => {
            const rowItem = item as Question;
            return (
              <Stack direction="row" gap="16px">
                <Button
                  variant="contained"
                  onClick={() => {
                    history.push(editQuestionUrl(rowItem.id));
                  }}
                >
                  EDIT
                </Button>

                <Button
                  variant="contained"
                  color="error"
                  onClick={() => {
                    onDeleteQuestion && onDeleteQuestion(rowItem);
                  }}
                >
                  DELETE
                </Button>
              </Stack>
            );
          },
        },
      ]}
    />
  );
};

export default CategoryQuestionsTable;
