import { Delete, Edit } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  Checkbox,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import { Box } from "@mui/system";
import DeleteModal from "components/DeleteModal";
import AddQuestionForm, {
  Question as FormQuestion,
} from "components/Questions/AddQuestionForm";
import { useAddQuestion } from "hooks/questions";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import ReactQuill from "react-quill";
import { Prompt, useHistory, useParams } from "react-router-dom";
import { viewCategoryDetailsUrl } from "config/urls";
import FilledCircleIcon from "components/Common";


/** Renders the add question to category view */
const AddQuestions = () => {
  const [questions, setQuestions] = useState<FormQuestion[]>([]);
  const [selectedQuestionIndexToDelete, setSelectedQuestionIndexToDelete] =
    useState<number>();

  const history = useHistory();

  const [selectedQuestionToEdit, setSelectedQuestionToEdit] =
    useState<FormQuestion>();

  const routeCategoryId = useParams<{ categoryId: string }>().categoryId;

  const routegroupId = useParams<{ groupId: string }>().groupId;

  const { mutateAsync: submitQuestion, isLoading } = useAddQuestion();

  useEffect(() => {
    const unLoadEventHandler = () => {
      if (questions.length > 0) {
        // if we have un-saved questions.
        return "Are you sure you want to continue without saving added questions? You will lose working data if you leave this page without saving!";
      }
    };

    window.onbeforeunload = unLoadEventHandler;

    return () => {
      window.onbeforeunload = null;
    };
  }, [questions]);

  const submitQuestionsToServer = async () => {
    try {
      const result = await submitQuestion({
        questions: questions,
        category_id: Number(routeCategoryId),
        group_id: Number(routegroupId),
      });
      toast("Question added successfully", { type: "success" });
      setQuestions([]);
      history.replace(viewCategoryDetailsUrl(routeCategoryId, routegroupId));
    } catch (error) {
      console.log(error);
      toast("Couldn't add question", { type: "error" });
    }
  };

  const addToQuestionList = async (question: FormQuestion) => {
    //fixes an issue where quill inserts un-neccessary line breaks.
    question.description = question.description.replaceAll("<p><br></p>", "");

    const newQuestions = [...questions, question];
    setQuestions(newQuestions);

    setSelectedQuestionToEdit(undefined);
  };

  const deleteQuestion = (index: number) => {
    const newQuestions = [...questions];
    newQuestions.splice(index, 1);
    setQuestions(newQuestions);
  };

  const editQuestion = (index: number) => {
    const question = questions[index];
    // if editing, remove from the list so when user adds it back, we don't have to worry about updating, we simply re-add
    deleteQuestion(index);
    setSelectedQuestionToEdit(question);
  };

  return (
    <div>
      <Prompt
        when={questions.length > 0}
        message="You have un-saved changes. Are you sure you want to leave?"
      />
      <Grid container spacing={4}>
        <Grid item xs={12} md={12} lg={7}>
          <Card sx={{ borderRadius: 3 }}>
            <Header>
              <Typography variant="subtitle1" fontWeight="bold">
                ADD QUESTION
              </Typography>
            </Header>

            <AddQuestionForm
              initialQuestion={selectedQuestionToEdit}
              onSubmit={addToQuestionList}
              autoClearFormOnSubmit
            />
          </Card>
        </Grid>

        <Grid item xs={12} md={12} lg={5}>
          {questions.length > 0 && (
            <Card sx={{ borderRadius: 3 }}>
              <Header>
                <Typography variant="body2" fontWeight="bold">
                  ADDED QUESTIONS
                </Typography>

                <Button
                  variant="contained"
                  disabled={isLoading}
                  onClick={() => submitQuestionsToServer()}
                >
                  {isLoading ? "PLEASE WAIT..." : "SUBMIT QUESTIONS"}
                </Button>
              </Header>
              {questions.map((question, index) => {
                return (
                  <QuestionContainer
                    key={`question-options-${question.options.length}-${index}`}
                  >
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                      >
                        <Box display="flex" width="100%" alignItems="center">
                          <ReactQuill
                            value={question.description}
                            readOnly
                            className="readonly-quill"
                          />
                          <Box flexDirection="row" display="flex">
                            <IconButton
                              color="primary"
                              onClick={() => editQuestion(index)}
                            >
                              <Edit fontSize="small" />
                            </IconButton>

                            <IconButton
                              color="error"
                              onClick={() =>
                                setSelectedQuestionIndexToDelete(index + 1)
                              }
                            >
                              <Delete fontSize="small" />
                            </IconButton>
                          </Box>
                        </Box>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box display="flex" marginBottom="8px">
                          <b>
                            Score:{" "}
                            <span style={{ fontWeight: "normal" }}>
                              {question.score}
                            </span>
                          </b>
                        </Box>
                        {question.options.map((option) => (
                          <Box
                            display="flex"
                            flexDirection="row"
                            sx={{ marginBottom: 2 }}
                            alignItems="center"
                          >
                            <Checkbox
                              icon={<FilledCircleIcon />}
                              checkedIcon={<CircleCheckedFilled />}
                              checked={option.is_answer}
                              disabled
                            />
                            <ReactQuill
                              value={option.description}
                              readOnly
                              className="readonly-quill"
                            />
                          </Box>
                        ))}
                      </AccordionDetails>
                    </Accordion>
                  </QuestionContainer>
                );
              })}
            </Card>
          )}
        </Grid>
      </Grid>

      <DeleteModal
        title="Are you sure?"
        prompt="You want to remove this question from the list of added questions?"
        onCancel={() => setSelectedQuestionIndexToDelete(undefined)}
        open={Number(selectedQuestionIndexToDelete) > 0}
        onConfirm={async () => {
          deleteQuestion(Number(selectedQuestionIndexToDelete) - 1);
          setSelectedQuestionIndexToDelete(undefined);
        }}
      />
    </div>
  );
};

/**
 * -------------------
 * STYLED COMPONENTS
 * -------------------
 */

const Header = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 12px;
  @media (min-width: 500px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  border-bottom: 2px dashed #efefee;
`;

const QuestionContainer = styled.div`
  background-color: #f9f9f9;
  border-radius: 5px;
  border: 1px solid #dfdfdf;
  margin: 8px;
  padding: 12px;
`;

export default AddQuestions;
