import { deleteRequest, get, post, put } from "api";
import { IFormStudent } from "components/Students/AddOrEditStudentForm";
import { MutationOptions } from "hooks/types";
import { QueryOptions, useMutation, useQuery } from "react-query";
import { Assessment, Attempts } from "types/assessments";
import { GenericAPIResponse } from "types/generic";
import { Category } from "types/questions";
import { ILevel, IStudent, IStudentGroup } from "types/students";

export const useStudents = (
  options?: QueryOptions<GenericAPIResponse<IStudent[]>>
) => {
  return useQuery<GenericAPIResponse<IStudent[]>, any>(
    ["students/"],
    () => get("admin/students/"),
    { ...(options || {}) }
  );
};

export const useStudentGroups = (
  options?: QueryOptions<GenericAPIResponse<IStudentGroup[]>>
) => {
  return useQuery<GenericAPIResponse<IStudentGroup[]>, any>(
    ["groups/"],
    () => get("admin/groups"),
    { ...(options || {}) }
  );
};

export const useAddStudent = (
  options?: MutationOptions<GenericAPIResponse<IStudent>>
) => {
  return useMutation((value: IFormStudent) => post("admin/students/", value), {
    ...(options || {}),
  });
};

export const useAddGroup = (
  options?: MutationOptions<GenericAPIResponse<IStudentGroup>>
) => {
  return useMutation((value: { name: string, level: number }) => post("admin/groups/", value), {
    ...(options || {}),
  });
};

export const useGroupDetails = (
  groupId: number | string,
  options?: QueryOptions<
    GenericAPIResponse<IStudentGroup & { subjects: Category[] }>
  >
) => {
  return useQuery<
    GenericAPIResponse<IStudentGroup & { subjects: Category[] }>,
    any
>(
    ["groups/", groupId],
    () => get(`admin/groups/${groupId}`),
    { ...(options || {}) }
  );
};


export const useUpdateStudent = (
    options?: MutationOptions<GenericAPIResponse<IStudent>>
  ) => {
    return useMutation((value: {student: IFormStudent, id: number}) => put(`admin/students/${value.id}`, value.student), {
      ...(options || {}),
    });
  };

  export const useUpdateGroup = (
    options?: MutationOptions<GenericAPIResponse<IStudentGroup>>
  ) => {
    return useMutation((value: {name: string, id: number, level: number}) => put(`admin/groups/${value.id}`, {name: value.name, level: value.level}), {
      ...(options || {}),
    });
  };

export const useDeleteStudent = (options?: MutationOptions<any>) => {
  return useMutation((id: number) => deleteRequest(`admin/students/${id}`), {
    ...(options || {}),
  });
};

export const useDeleteGroup = (options?: MutationOptions<any>) => {
  return useMutation((id: number) => deleteRequest(`admin/groups/${id}`), {
    ...(options || {}),
  });
};

export const useStudentAssessments = (
  options?: QueryOptions<GenericAPIResponse<(Assessment & {attempt: Attempts})[]>>
) => {
  return useQuery<GenericAPIResponse<(Assessment & {attempt: Attempts})[]>, any>(
    ["students/assessments"],
    () => get("students/assessments"),
    { ...(options || {}) }
  );
};

export const useLevels = (
  options?: QueryOptions<GenericAPIResponse<ILevel[]>>
) => {
  return useQuery<GenericAPIResponse<ILevel[]>, any>(
    ["admin/levels"],
    () => get("admin/levels/"),
    { ...(options || {}) }
  );
};

