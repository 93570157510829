import { Edit } from "@material-ui/icons";
import { Add, Delete } from "@mui/icons-material";
import { Box, Stack } from "@mui/material";
import { isAdminUser } from "api/cookie";
import Dropdown from "components/Dropdown";
import Table, { RowData } from "components/Table";
import Button from "components/button/Button";
import { viewClassDetailsUrl } from "config/urls";
import { useMemo } from "react";
import { useHistory } from "react-router-dom";
import { IStudentGroup } from "types/students";

interface IStudentGroupTableProps {
  groups: IStudentGroup[];
  onAddGroupClicked?: Function;
  onDeleteGroupClicked: (group: IStudentGroup) => void;
  onEditGroupClicked: (group: IStudentGroup) => void;
}

const StudentGroupListTable = ({
  groups,
  onAddGroupClicked,
  onDeleteGroupClicked,
  onEditGroupClicked,
}: IStudentGroupTableProps) => {
  const data = useMemo(() => {
    return groups.map((group, pos) => {
      return { ...group, index: pos + 1 };
    });
  }, [groups]);

  const history = useHistory();
  const isAdmin = isAdminUser();
  return (
    <Table
      title={{
        label: "Classes",
      }}
      ctaLabel="ADD CLASS"
      onCTAClicked={isAdmin ? onAddGroupClicked : undefined}
      columns={[
        {
          id: "index",
          label: "S/N",
        },
        {
          id: "name",
          label: "Name",
        },
        {
          id: "level",
          label: "Level",
          renderer: (item: RowData) => item?.level?.level_name,
        },
        {
          id: "",
          label: "ACTIONS",
          renderer: (item: RowData) => {
            return (
              <Stack direction="row" justifyContent="center">
                <Button
                  variant="contained"
                  onClick={() => {
                    history.push(viewClassDetailsUrl(item.id));
                  }}
                >
                  VIEW CLASS
                </Button>

                {isAdmin && (
                  <Dropdown
                    containerStyle={{ marginLeft: 16, display: "inline" }}
                    title="Actions"
                    buttonProps={{
                      sx: {
                        borderRadius: 25,
                        background: "none !important",
                        border: "1px solid #CCC",
                        color: "black",
                      },
                    }}
                    items={[
                      {
                        label: "UPDATE",
                        icon: <Edit />,
                        onClick: () => {
                          onEditGroupClicked &&
                            onEditGroupClicked(item as IStudentGroup);
                        },
                      },
                      {
                        label: "DELETE",
                        icon: <Delete />,
                        onClick: () =>
                          onDeleteGroupClicked &&
                          onDeleteGroupClicked(item as IStudentGroup),
                      },
                    ]}
                  />
                )}
              </Stack>
            );
          },
        },
      ]}
      rows={data}
    />
  );
};

export default StudentGroupListTable;
