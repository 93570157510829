import React, { useState } from "react";
import PageLayout from "components/PageLayout";

import { toast } from "react-toastify";
import { AxiosError } from "axios";
import DeleteModal from "components/DeleteModal";
import { useAddUser, useDeleteUser, useUpdateUser, useUsers } from "hooks/users";
import { IUsers } from "types/users";
import UsersListTable from "components/Users/UsersListTable";
import AddUserModal from "components/Users/AddUserModal";

const UsersView = () => {
  const {
    isLoading,
    isFetching,
    error,
    refetch,
    data: users,
  } = useUsers();

  const { mutateAsync: submitUserAsync } = useAddUser();
  const { mutateAsync: updateUserAsync } = useUpdateUser();
  const { mutateAsync: deleteUserFromServer } = useDeleteUser();

  const [showAddOrEditUserModal, setShowAddOrEditUserModal] =
    useState(false);
  const [selectedUser, setSelectedUser] = useState<IUsers | null>(null);
  const [showDeleteUserModal, setShowDeleteUserModal] = useState(false);

  const submitUserToServer = async (user: IUsers) => {
    try {
      const result = await (selectedUser
        ? updateUserAsync({ user, id: selectedUser.id! })
        : submitUserAsync(user));
      toast(
        result.message || selectedUser
          ? "User updated successfully"
          : "User created successfully!"
      );
      refetch();
      setSelectedUser(null);
      setShowAddOrEditUserModal(false);
    } catch (error) {
      toast(
        (error as AxiosError)?.response?.data?.message ||
          "Couldn't create User",
        { type: "error" }
      );
    } 
  };

  const deleteUser = async (id: number) => {
    try {
      const result = await deleteUserFromServer(id);
      toast(result.message || "User deleted successfully!");
      refetch();
    } catch (error) {
      toast(
        (error as AxiosError)?.response?.data?.message ||
          "Couldn't delete student",
        { type: "error" }
      );
    } finally {
      setShowDeleteUserModal(false);
      setSelectedUser(null);
    }
  };

  return (
    <PageLayout
      loading={isLoading || isFetching}
      error={error}
      onRetry={() => refetch()}
    >
      <UsersListTable
        users={users?.data || []}
        onAddUserClicked={() => setShowAddOrEditUserModal(true)}
        onDeleteUser={(user) => {
          setSelectedUser(user);
          setShowDeleteUserModal(true);
        }}
        onEditUser={(user) => {
          setSelectedUser(user);
          setShowAddOrEditUserModal(true);
        }}
      />

      <AddUserModal
        open={showAddOrEditUserModal}
        onCancel={() => {
          setShowAddOrEditUserModal(false);
          setSelectedUser(null);
        }}
        onSubmit={submitUserToServer}
        initialUser={selectedUser || undefined}
        inEditMode={Boolean(selectedUser)}
      />

      <DeleteModal
        title="Delete User"
        prompt="Are you sure you want to delete this user?"
        open={Boolean(selectedUser && showDeleteUserModal)}
        onCancel={() => {
          setSelectedUser(null);
          setShowDeleteUserModal(false);
        }}
        onConfirm={async () => {
          await deleteUser(selectedUser?.id!);
        }}
      />
    </PageLayout>
  );
};

export default UsersView;
