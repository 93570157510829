import React, { useState } from "react";
import PageLayout from "components/PageLayout";
import {
  useAddGroup,
  useDeleteGroup,
  useLevels,
  useStudentGroups,
  useUpdateGroup,
} from "hooks/students";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import { IStudentGroup } from "types/students";
import DeleteModal from "components/DeleteModal";
import StudentGroupListTable from "components/Students/StudentGroupListTable";
import AddOrEditGroupModal from "components/Students/AddOrEditGroupModal";

const StudentGroupView = () => {
  const {
    isLoading,
    isFetching,
    error,
    refetch,
    data: groups,
  } = useStudentGroups();

  const {
    data: levels,
  } = useLevels();

  const { mutateAsync: submitGroupAsync } = useAddGroup();
  const { mutateAsync: updateGroupAsync } = useUpdateGroup();
  const { mutateAsync: deleteGroupFromServer } = useDeleteGroup();

  const [showAddOrEditGroupModal, setShowAddOrEditGroupModal] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState<IStudentGroup | null>(
    null
  );
  const [showDeleteGroupModal, setShowDeleteGroupModal] = useState(false);

  const submitGroupToServer = async (group: Partial<IStudentGroup>) => {
    try {
      const result = await (selectedGroup
        ? updateGroupAsync({ id: selectedGroup.id, name: group.name!, level: group.level_code! })
        : submitGroupAsync({ name: group.name!, level: group.level_code! }));
      toast(
        result.message || selectedGroup
          ? "Level updated successfully"
          : "Level saved successfully!"
      );
      refetch();
    } catch (error) {
      toast(
        (error as AxiosError)?.response?.data?.message ||
          "Couldn't save this level",
        { type: "error" }
      );
    } finally {
      setSelectedGroup(null);
      setShowAddOrEditGroupModal(false);
    }
  };

  const deleteGroup = async (id: number) => {
    try {
      const result = await deleteGroupFromServer(id);
      toast(result.message || "Level deleted successfully!");
      refetch();
    } catch (error) {
      toast(
        (error as AxiosError)?.response?.data?.message ||
          "Couldn't delete this level",
        { type: "error" }
      );
    } finally {
      setShowDeleteGroupModal(false);
      setSelectedGroup(null);
    }
  };

  return (
    <PageLayout
      loading={isLoading || isFetching}
      error={error}
      onRetry={() => refetch()}
    >
      <StudentGroupListTable
        groups={groups?.data || []}
        onDeleteGroupClicked={(group) => {
          setSelectedGroup(group);
          setShowDeleteGroupModal(true);
        }}
        onEditGroupClicked={(group) => {
          setSelectedGroup(group);
          setShowAddOrEditGroupModal(true);
        }}
        onAddGroupClicked={() => {
          setShowAddOrEditGroupModal(true);
        }}
      />

      <AddOrEditGroupModal
        inEditMode={Boolean(selectedGroup)}
        open={showAddOrEditGroupModal}
        initialName={selectedGroup?.name}
        initialLevel={selectedGroup?.level_code}
        levels={levels?.data || []}
        onSubmit={({name, level}) => submitGroupToServer({ name, level_code: level })}
        onCancel={() => {
          setShowAddOrEditGroupModal(false);
          setSelectedGroup(null);
        }}
      />

      <DeleteModal
        title="Delete Level"
        prompt="Are you sure you want to delete level?"
        open={Boolean(selectedGroup && showDeleteGroupModal)}
        onCancel={() => {
          setSelectedGroup(null);
          setShowDeleteGroupModal(false);
        }}
        onConfirm={async () => {
          await deleteGroup(selectedGroup!.id);
        }}
      />
    </PageLayout>
  );
};

export default StudentGroupView;
