import { deleteRequest, get, post, put } from "api";
import { MutationOptions } from "hooks/types";
import { QueryOptions, useMutation, useQuery } from "react-query";
import { GenericAPIResponse } from "types/generic";
import { IUsers, Institution } from "types/users";

export const useUsers = (
    options?: QueryOptions<GenericAPIResponse<IUsers[]>>
  ) => {
    return useQuery<GenericAPIResponse<IUsers[]>, any>(
      ["users/"],
      () => get("admin/users/"),
      { ...(options || {}) }
    );
  };

  export const useAddUser = (
    options?: MutationOptions<GenericAPIResponse<IUsers>>
  ) => {
    return useMutation((value: IUsers) => post("admin/users/", value), {
      ...(options || {}),
    });
  };

  export const useUpdateUser = (
    options?: MutationOptions<GenericAPIResponse<IUsers>>
  ) => {
    return useMutation((value: {user: IUsers, id: number}) => put(`admin/users/${value.id}`, value.user), {
      ...(options || {}),
    });
  };

  export const useDeleteUser = (options?: MutationOptions<any>) => {
    return useMutation((id: number) => deleteRequest(`admin/users/${id}`), {
      ...(options || {}),
    });
  };

  export const useAddInstitution = (
    options?: MutationOptions<GenericAPIResponse<Institution>>
  ) => {
    return useMutation(
      (value: Partial<Institution>) => post("admin/institution", value),
      {
        ...(options || {}),
      }
    );
  };
  