import { Card, Grid, Typography } from "@mui/material";
import { AxiosError } from "axios";
import { useAddInstitution } from "hooks/users";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";

import { Institution } from "types/users";
import InstitutionForm from "./AddInstitutionForm";

const AddInstitution = () => {
  const history = useHistory();
  const { mutateAsync: submitInsitution } = useAddInstitution();

  const submitInstitutionToServer = async (institution: Partial<Institution>) => {
    try {
      await submitInsitution(institution);
      toast("Institution added successfully. You can now login with the user account!", { type: "success" });
      setTimeout(() => {
        window.location.reload()
      },5000)
    } catch (error) {
      toast(
        (error as AxiosError)?.response?.data?.message ||
          "Couldn't add institution",
        { type: "error" }
      );
    }
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={12} lg={7}>
        <Card sx={{ borderRadius: 3 }}>
          <Header>
            <Typography variant="subtitle1" fontWeight="bold">
              ADD INSTITUTION
            </Typography>
          </Header>
            <div style={{padding: 16}}>
            <InstitutionForm onSubmit={submitInstitutionToServer} />
            </div>
        </Card>
      </Grid>
    </Grid>
  );
};

/**
 * -------------------
 * STYLED COMPONENTS
 * -------------------
 */

const Header = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 2px dashed #efefee;
`;

export default AddInstitution;
