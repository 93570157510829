import { Modal } from "@mui/material";
import ModalContainer from "components/ModalContainer";
import { IUsers } from "types/users";
import AddOrEditUserForm from "./AddorEditUserFrom";

interface IAddUserModalProps {
  onSubmit: (user: IUsers) => Promise<void | any>;
  onCancel: Function;
  open?: boolean;
  inEditMode?: boolean;
  initialUser?: Partial<IUsers>;
}

const AddUserModal = ({
  onSubmit,
  onCancel,
  open = false,
  inEditMode,
  initialUser,
}: IAddUserModalProps) => {
  return (
    <Modal open={open} onClose={() => onCancel && onCancel()}>
      <ModalContainer
        title={inEditMode ? "EDIT USER" : "ADD USER"}
        handleClose={onCancel}
        style={{ width: 640 }}
      >
        <AddOrEditUserForm 
         inEditMode={inEditMode}
         onSubmit={onSubmit}
         initialUser={inEditMode ? initialUser : undefined}
        />
      </ModalContainer>
    </Modal>
  );
};

export default AddUserModal;
