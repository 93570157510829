import { createTheme } from "@mui/material";
import { accent, primary, white } from "./colors";

const Theme = createTheme({
    palette:{
        primary:{
            main:primary,
            contrastText: white
        },
        secondary: {
            main: accent,
            contrastText: white
        }
    },
})

export default Theme;