import TextField from "components/Form/TextField";
import { Box, MenuItem, Modal } from "@mui/material";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import ModalContainer from "components/ModalContainer";
import Button from "components/button/Button";
import { ILevel } from "types/students";

interface IAddCategoryModalProps {
  onSubmit: (value: {name: string; level: number}) => Promise<void | any>;
  onCancel: Function;
  open?: boolean;
  initialName?: string;
  initialLevel?: number;
  levels: ILevel[];
  title?: string;
}

const addCategoryFormValidator = Yup.object().shape({
  name: Yup.string()
    .required("Enter subject name")
    .min(3, "Name must be more than two characters"),
  level: Yup.number().required("Please select the right level"),
});

const AddCategoryModal = ({
  onSubmit,
  onCancel,
  initialName,
  initialLevel,
  levels,
  open = false,
  title = "ADD SUBJECT",
}: IAddCategoryModalProps) => {
  const initialValues = { name: initialName, level: initialLevel };

  return (
    <Modal open={open} onClose={() => onCancel && onCancel()}>
      <ModalContainer
        title={title}
        handleClose={onCancel}
        style={{ width: 450 }}
      >
        <Formik
          initialValues={initialValues}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true);
            await onSubmit({name: values.name!, level: values.level!});
            setSubmitting(false);
          }}
          validationSchema={addCategoryFormValidator}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            isSubmitting,
          }) => (
            <Form
              style={{ textAlign: "center", marginTop: 12, marginBottom: 24 }}
            >
              <TextField
                type="text"
                name="name"
                error={!!(errors.name && touched.name)}
                helperText={errors.name}
                onChange={handleChange}
                onBlur={handleBlur}
                required
                value={values.name || ""}
                label="Subject Name"
                variant="outlined"
                fullWidth
                style={{ marginBottom: 24 }}
              />

              <TextField
                name="level"
                error={!!(errors.level && touched.level)}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={errors.level}
                label="Class Level"
                select
                value={values.level}
                fullWidth
                style={{ textAlign: "left" }}
              >
                {levels?.map((level) => (
                  <MenuItem key={level.level_code} value={level.level_code}>
                    {level.level_name}
                  </MenuItem>
                ))}
              </TextField>

              <Box display="flex" justifyContent="center">
                <Button
                  type="submit"
                  variant="contained"
                  disabled={isSubmitting}
                  style={{ marginTop: 32, minWidth: 180 }}
                >
                  SUBMIT
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </ModalContainer>
    </Modal>
  );
};

export default AddCategoryModal;
